.iconInputDivider {
  position: absolute;
  border-left: var(--spacing-1) solid var(--widget-stroke-color);
  height: 1.3125rem;
  top: 26%;
  margin-left: 120px;
}

.filtersContainer {
  display: inline-flex;
  width: 100%;
}

.filterByStatusContainer {
  border: 0;
}

.selectStyle {
  padding: 9px 10px;
  border: 0;
  border-radius: 3px;
  font-size: 12px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  width: 110px;
  background: url(../../../media/Sorting.svg) 67% / 10% no-repeat;
}

.filterByStatusSelectStyle {
  padding: 9px 10px;
  border: 0;
  border-radius: 3px;
  font-size: 12px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  position: absolute;
  padding-right: 130px;
  margin-left: 50px;
  background: url(../../../media/Filter.svg) 40% / 7% no-repeat;
}
