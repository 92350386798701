.scrollAnchor {
  text-decoration: none !important;
}

.scrollContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 300px !important;
  padding: 10px 0 0 5px;
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

.scrollToTopButtonContainer {
  position: sticky;
  bottom: 0;
}

.scrollToTopButton {
  display: flex;
  align-items: center;
  margin: 5px auto 5px auto;
  color: var(--livi-primary-color) !important;
  background-color: var(--livi-secondary-color) !important;
  border-radius: 5px;
  width: fit-content !important;
}

.scrollToTopIcon {
  height: 20px;
}

.scrollToTopAnchor {
  text-decoration: none !important;
}
