.timeSchedule {
  display: flex;
  align-items: center;
}

.calendar {
  --icon-margin: 1.25rem;

  margin-right: var(--icon-margin);
  flex: 1;
}

.calendar path {
  fill: var(--highlight-font-color);
}

.messageWrapper {
  flex: 6;
}

.message {
  letter-spacing: 0;
  line-height: 1.125rem;
  color: var(--default-font-color);
}
