.errorImage {
  --image-side: 4.1875rem;

  height: var(--image-side);
  width: var(--image-side);
}

.errorImage path:nth-child(3),
.errorImage path:nth-child(2),
.errorImage path:nth-child(4) {
  fill: var(--widget-text-soft-color);
}

.errorImage path:nth-child(5) {
  fill: var(--widget-background-color);
}

.errorImage rect {
  stroke: var(--widget-stroke-color);
  stroke-opacity: 1;
}

.errorTitle {
  margin-top: var(--spacing-5);
}

.errorInfo {
  padding-top: var(--spacing-5);
  padding-bottom: 2rem;
}
