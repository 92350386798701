.dotWrapper {
  display: flex;
  align-items: center;
}

.dotWrapper > i {
  --dot-margin: 0.09375rem;

  margin: 0 var(--dot-margin);
}

.dot {
  --dot-radius: 0.375rem;

  width: var(--dot-radius);
  height: var(--dot-radius);
  border-radius: 50%;
  background-color: var(--widget-stroke-color);
}

.dot.selected {
  background-color: var(--livi-primary-color);
}

.wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
