.appointmentDateTime > div > input {
  cursor: pointer;
}

.appointmentDateTime > div > input:not(.invalid):focus {
  border-color: var(--widget-stroke-color);
}

.appointmentDateTime {
  height: 3.5rem;
}

.inputWrapper {
  padding-top: var(--spacing-5);
  border-top: var(--spacing-1) solid var(--widget-stroke-color);
}
