.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.liviContactInfo {
  padding: var(--spacing-4) 0 var(--spacing-4) 0;
  white-space: pre-wrap;
  letter-spacing: 0;
}

.supportPhoneNumber {
  margin-bottom: var(--spacing-5);
  color: var(--default-font-color);
}

.logoutButtonContainer {
  padding-top: 1.5rem;
  padding-bottom: var(--spacing-5);
  width: 100%;
  border-top: var(--spacing-1) solid var(--widget-stroke-color);
}

hr {
  width: 100%;
  height: 1px;
  background-color: var(--widget-stroke-color);
  margin: var(--spacing-5) 0 var(--spacing-5);
}
