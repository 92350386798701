.textInputLabel {
  margin-bottom: 2px;
  display: block;
}

.textInputWrapper {
  position: relative;
  width: 100%;
}

.validationIcon {
  position: absolute;
  right: 0;
  line-height: 1.15em;
  padding: 0.5rem;
}

.textInput {
  color: var(--default-font-color);
  padding: 0.5em;
  border-radius: var(--border-radius);
  border: 1.3px solid var(--widget-stroke-color);
  outline: none;
  display: block;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 0.8125rem;
  white-space: nowrap;
  background-color: var(--input-field-background);
}

.textInput.invalid {
  border-color: var(--alert-color);
  margin-bottom: var(--spacing-2);
}

.textInput:disabled {
  background-color: var(--livi-secondary-color);
  border-color: var(--livi-secondary-color);
}

.textInput:not(.invalid):focus,
.textInput.valid {
  border-color: var(--livi-primary-color);
}

.validationError {
  display: block;
  text-align: right;
  color: var(--alert-color);
}
