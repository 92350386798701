.successViewWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.successIcon {
  --icon-side: 3.625rem;

  width: var(--icon-side);
  height: var(--icon-side);
}

.successIcon path {
  fill: var(--widget-text-soft-color);
  fill-opacity: 0.5;
}

.successHeader {
  margin-top: var(--spacing-5);
}

.successInfo {
  padding-top: var(--spacing-5);
  padding-bottom: 1.5rem;
}
