:root {
  --widget-black-color: #0e2c38;
  --livi-primary-color: #0a7e85;
  --livi-secondary-color: #f1f6f9;
  --livi-primary-hover-color: #155156;
  --alert-color: #db364f;
  --alert-hover-color: #98111f;
  --widget-stroke-color: rgba(0, 0, 0, 0.2);
  --widget-text-soft-color: #818181;
  --widget-scroll-color: lightgrey;
  --active-menu-icon-bg-color: white;
  --active-menu-icon-color: var(--widget-black-color);
  --inactive-menu-icon-bg-color: var(--widget-black-color);
  --inactive-menu-icon-color: white;
  --hover-menu-icon-color: white;
  --hover-menu-icon-bg-color: var(--livi-primary-color);
  --header-divider-color: rgb(255, 255, 255, 0.5);
  --button-text-color: white;
  --secondary-button-color: white;
  --widget-background-color: white;
  --transparent-button-text-color: black;
  --default-font-color: var(--widget-black-color);
  --highlight-font-color: var(--livi-primary-color);
  --input-field-background: white;
  --divider-color: white;
  --divider-color-dark-1: lightgrey;
  --day-slot-date: purple;
}

@media (prefers-color-scheme: dark) {
  :root {
    --widget-background-color: #272727;
    --default-font-color: white;
    --widget-black-color: #333;
    --input-field-background: #333;
    --active-menu-icon-bg-color: #333;
    --active-menu-icon-color: #0a7e85;
    --inactive-menu-icon-bg-color: #333;
    --inactive-menu-icon-color: white;
    --hover-menu-icon-color: #0a7e85;
    --hover-menu-icon-bg-color: #333;
    --highlight-font-color: white;
    --widget-stroke-color: rgba(255, 255, 255, 0.2);
    --livi-secondary-color: #333;
    --transparent-button-text-color: white;
    --secondary-button-color: #333;
    --widget-text-soft-color: #989797;
  }
}
