.slot {
  width: 25%;
  text-align: center;
  margin: var(--spacing-4) 0;
  border-radius: var(--border-radius);
}

.slot > button {
  --slot-padding: 0.375rem 0.625rem;

  padding: var(--slot-padding);
}

.slot.selected > button {
  --button-text-color: white;

  background-color: var(--livi-primary-color) !important;
  color: var(--button-text-color) !important;
}

.slot.disabled > button {
  text-decoration: line-through;
}

.timeScheduleInput {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-bottom: var(--spacing-1) solid var(--widget-stroke-color);
  padding-bottom: var(--spacing-5);
}

.paginationButton {
  display: flex;
  text-transform: uppercase;
  line-height: 0.875rem;
}

.paginationButton > *:not(:last-child) {
  margin-right: var(--spacing-3);
}

.paginationButton > span {
  overflow: hidden;
  width: 2.75rem;
}

.chevron path {
  fill: var(--default-font-color);
}
