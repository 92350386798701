.container {
  background-color: var(--livi-secondary-color);
  border-radius: var(--border-radius);
  padding: var(--spacing-4) var(--spacing-5);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
