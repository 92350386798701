.bookingsSummaryMessageContainer {
  display: flex;
  width: 100%;
  color: var(--widget-text-soft-color);
  padding: 5px;
  justify-content: center;
}

.bookingsSummaryMessage {
  color: var(--widget-text-soft-color);
  font-size: 10px;
}

.cardSeparator {
  padding: 0;
  margin: 0;
}
