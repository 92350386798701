.button {
  --button-padding: 0.625rem;

  border-radius: var(--border-radius);
  box-sizing: border-box;
  padding: var(--button-padding);
  overflow: hidden;
}

.button:disabled {
  opacity: 0.25;
}

.button:not(:disabled) {
  cursor: pointer;
}

.button.primary,
.button.secondary,
.button.alert,
.button.secondaryAlert {
  display: flex;
  flex-grow: 0;
  justify-content: center;
  border-style: solid;
  border-width: var(--spacing-1);
  width: 100%;
  font-weight: bold;
  letter-spacing: 0.25px;
  border-color: var(--livi-primary-color);
  color: var(--button-text-color);
}

.button.secondary {
  color: var(--livi-primary-color);
  border-color: var(--livi-primary-color);
  background-color: var(--secondary-button-color);
}

.button.alert {
  background-color: var(--alert-color);
  border-color: var(--alert-color);
}

.button.secondaryAlert {
  color: var(--alert-color);
  border-color: var(--alert-color);
  background-color: var(--secondary-button-color);
}

.button.primay:hover,
.button.secondary:hover,
.button.alert:hover {
  border-color: var(--livi-primary-hover-color);
}

.button.primary {
  background-color: var(--livi-primary-color);
  border-color: var(--livi-primary-color);
}

.button.primary:hover {
  background-color: var(--livi-primary-hover-color);
  border-color: var(--livi-primary-hover-color);
}

.button.secondary:hover {
  color: var(--livi-primary-hover-color);
  border-color: var(--livi-primary-hover-color);
}

.button.alert:hover {
  background-color: var(--alert-hover-color);
  border-color: var(--alert-hover-color);
}

.button.secondaryAlert:hover {
  color: var(--alert-hover-color);
  border-color: var(--alert-hover-color);
}

.button.transparent {
  display: inline-block;
  border: none;
  background-color: transparent;
  color: var(--transparent-button-text-color);
  overflow: hidden;
}

.button.transparent.active {
  border: none;
}

.button.transparent:hover:not(:disabled) {
  background-color: var(--livi-secondary-color);
}

.link {
  text-decoration: none;
  width: 100%;
}

.button.backButton {
  align-items: center !important;
  background-color: transparent !important;
  border: none !important;
  color: var(--default-font-color) !important;
  font-size: var(--strong-font-size) !important;
  font-weight: 500 !important;
  max-width: fit-content !important;
  padding: 0 !important;
}

.backChevron {
  padding-right: 5px;
}

.backChevron path {
  fill: var(--highlight-font-color);
}

.button.backButton:hover {
  background-color: transparent !important;
}

@media (prefers-color-scheme: dark) {
  .button.secondary {
    border: none;
  }
}
