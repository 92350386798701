.searchTextInputWrapper {
  direction: rtl;
}

.iconInputDivider {
  position: absolute;
  border-left: var(--spacing-1) solid var(--widget-stroke-color);
  height: 1.3125rem;
  top: 25%;
  right: 2.375rem;
}

.iconInputDivider.invalid {
  border-left: var(--spacing-1) solid var(--alert-color);
}

.searchIcon {
  position: absolute;
  padding: 0.625rem;
}

.searchIcon path {
  fill: var(--highlight-font-color);
}
