.icon {
  padding: var(--widget-padding);
}

.infoWrapper {
  text-align: center;
}

.fullyBookedView {
  flex-direction: column;
}
