.errorView {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.errorImage path:nth-child(4) {
  fill: var(--widget-text-soft-color);
}

.errorImage path:nth-child(3) {
  fill: var(--widget-background-color);
}

.errorImage path:nth-child(1),
.errorImage path:nth-child(2) {
  stroke: var(--widget-text-soft-color);
  stroke-opacity: 0.3;
}

.errorImage path:nth-child(5) {
  fill: var(--widget-background-color);
}
