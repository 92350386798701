.collapsed {
  display: flex;
  height: 100%;
}

.expanded {
  margin-left: var(--spacing-1);
  padding: var(--widget-padding);
  width: var(--widget-width);
  min-height: calc(
    (var(--header-height) * var(--max-menu-items)) - var(--widget-padding)
  );
  display: flex;
  flex-direction: column;
  background-color: var(--widget-background-color);
  border-radius: 0 0 var(--border-radius) var(--border-radius);
}

.expanded > *:not(:last-child) {
  margin-bottom: var(--spacing-4);
}

.login {
  width: 18.4375rem;
  height: 35.9375rem;
  padding: 0;
}
