.wrapper {
  margin: 1px 1px 0 1px;
  user-select: none;
  display: flex;
  height: var(--header-height);
  justify-content: space-between;
  background-color: var(--widget-black-color);
  color: white;
  border-radius: var(--border-radius) var(--border-radius) 0 0;
  position: relative;
  width: var(--header-width);
  -webkit-app-region: drag;
}

.expanded {
  width: calc(100% - 2px);
}

.logoWrapper {
  height: var(--header-height);
  width: var(--header-width);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.logoWrapper.expanded {
  border-left: 1px solid var(--header-divider-color);
}

.headerLogo {
  width: 1.5rem;
}

.subtitle {
  --subtitle-devel-bg-color: #db364f;
  --subtitle-beta-bg-color: #fbd100;
  --subtitle-alpha-bg-color: #03a3f4;
  --subtitle-font-size: 0.5rem;

  font-weight: 500;
  font-size: var(--subtitle-font-size);
  border-radius: var(--border-radius);
  margin-top: var(--spacing-3);
  padding: var(--spacing-1) 0;
  width: 1.875rem;
  text-align: center;
  color: var(--widget-black-color);
}

.subtitle.production {
  display: none;
}

.subtitle.alpha {
  background-color: var(--subtitle-alpha-bg-color);
}

.subtitle.alpha::after {
  content: 'Alpha';
}

.subtitle.beta {
  background-color: var(--subtitle-beta-bg-color);
}

.subtitle.beta::after {
  content: 'Beta';
}

.subtitle.devel {
  background-color: var(--subtitle-devel-bg-color);
}

.subtitle.devel::after {
  content: 'Devel';
}
