.topSlot {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 20px;
  padding: 0 0 10px 0;
}

.topSlot > .appTitle {
  align-self: center;
  color: var(--default-font-color);
  font-size: var(--strong-font-size);
  font-weight: 500;
  letter-spacing: 0;
}
