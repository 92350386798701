.bookingInfoWrapper {
  padding-top: var(--spacing-5);
  border-top: var(--spacing-1) solid rgba(0, 0, 0, 0.2);
}

.confirmationInfo {
  padding: var(--spacing-2) var(--spacing-4) 0.625rem var(--spacing-4);
}

.info {
  padding-bottom: var(--spacing-5);
  font-size: 14px;
}
