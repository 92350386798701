.titleWrapper {
  border-radius: var(--border-radius) var(--border-radius) 0 0;
  padding: 0 var(--spacing-4);
}

.title {
  padding: 0.75rem var(--spacing-5) 0 var(--spacing-4);
  display: inline-flex;
}

.closeIcon {
  cursor: pointer;
  margin-right: 1.125rem;
  -webkit-app-region: no-drag;
}

.closeIcon path {
  fill: white;
}

.titleText {
  color: white;
}
