.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-top: 1px solid var(--divider-color-dark-1);
  border-bottom: 1px solid var(--divider-color-dark-1);
  padding-top: 25px;
  margin-bottom: 15px;
}

.noBookingsTitle {
  display: flex;
  word-break: break-all;
  text-align: center;
  font-size: var(--paragraph-font-size);
  font-weight: 700;
  padding: 20px 0 0 0;
  max-width: 75%;
  letter-spacing: 0;
}

.noBookingsMessage {
  display: flex;
  word-break: break-all;
  text-align: center;
  font-size: var(--paragraph-font-size);
  padding: 5px 0 5px 5px;
  max-width: 75%;
  letter-spacing: 0;
}

.noBookingsCtaButton {
  margin-top: 30px;
  margin-bottom: 5px;
  font-size: var(--strong-font-size);
}
