.searchIcon {
  position: absolute;
  padding: 0.625rem;
}

.textInputLabel {
  margin-bottom: 2px;
  display: block;
}

.validationError {
  display: block;
  text-align: right;
  color: var(--alert-color);
}
