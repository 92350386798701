.wrapper {
  margin: 0 var(--spacing-1) var(--spacing-1) var(--spacing-1);
  user-select: none;
}

.wrapper.expanded {
  margin-left: 0;
}

.menu {
  width: var(--header-width);
  border-radius: 0 0 var(--border-radius) var(--border-radius);
  position: relative;
}

.menu.expanded {
  border-radius: 0 0 var(--border-radius) 0;
}

.sideMenuIcon {
  --side-menu-icon-padding: 0.703125rem 0.578125rem;

  padding: var(--side-menu-icon-padding);
}

.threeDots {
  --dots-padding: 1.0625rem 0.625rem 0.9375rem 0.625rem;

  padding: var(--dots-padding);
}

.power {
  --power-padding: 0.3125rem;

  padding: var(--power-padding);
}

.divider {
  --divider-height: 0.0625rem;

  width: var(--menu-item-divider-width);
  background-color: var(--divider-color);
  height: var(--divider-height);
  position: absolute;
  left: calc(50% - var(--menu-item-divider-width) / 2);
}

/* This hides the two adjacent dividers when hovering a menu button or selecting it */
.item:hover > .divider,
.item.selected > .divider,
.item:hover + .item > .divider,
.item.selected + .item > .divider {
  display: none;
}

.item {
  cursor: pointer;
  -webkit-app-region: no-drag;
  height: var(--header-height);
  background-color: var(--inactive-menu-icon-bg-color);
}

.item path {
  fill: var(--inactive-menu-icon-color);
}

.item.selected {
  background-color: var(--active-menu-icon-bg-color);
}

.item.selected path {
  fill: var(--active-menu-icon-color);
}

.item:last-child {
  border-radius: 0 0 var(--border-radius) var(--border-radius);
}

.item:last-child.expanded {
  border-radius: 0 0 var(--border-radius) 0;
}

.item:hover {
  background-color: var(--hover-menu-icon-bg-color);
}

.item:hover path {
  fill: var(--hover-menu-icon-color);
}
