.bookingCard {
  width: 100%;
  margin: 4px 0 4px 0;
  padding: 5px;
}

.bookingCard:hover {
  border-radius: 5px;
  background-color: var(--livi-secondary-color);
}

.bookingCard > .bookingCardMobileNumber {
  padding: 5px;
  font-size: 14px;
}

.bookingCard > .bookingCardNhsAndTime {
  padding: 5px;
}

.bookingCardNhsAndTimeSeparator {
  margin: 5px !important;
}

.bookingCard > .bookingCardNhsNumber {
  padding: 5px;
  color: var(--widget-text-soft-color);
  font-size: 11px;
}

.bookingCardAppointmentTime {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 13px;
  letter-spacing: 0.25px;
  color: #000 !important;
}

.cardSeparator {
  padding: 0;
  margin: 0;
}

.childChip {
  align-items: center;
  display: inline-flex;
  justify-content: center;
  background-color: #cdf1fd;
  border-radius: 9999px;
  padding: 2px 10px;
  margin: 3px !important;
  font-weight: 600;
}
