.failViewWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.supportPhoneNumber {
  margin-bottom: 1.5rem;
  color: var(--default-font-color);
}
