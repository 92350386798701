.daySlot {
  width: 100%;
}

.daySlotTitleCard {
  display: flex;
  align-items: center;
  background-color: var(--livi-secondary-color);
  height: 35px;
  max-width: initial;
  border-radius: 5px;
  padding: 0 10px 0 10px;
}

.daySlotTitle {
  align-self: center;
  color: var(--default-font-color);
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
}

.daySlotDay {
  font-weight: 600;
}

.daySlotDate {
  color: var(--day-slot-date);
}

.bookingCardsContainer {
  width: 95%;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  color: var(--widget-scroll-color);
  max-height: 50px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--widget-scroll-color);
  max-height: 50px;
}
