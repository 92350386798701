.weekInput {
  --navigate-week-button-width: 1rem;
  --navigate-week-button-height: 2rem;
  --week-day-container-width: 2rem;
  --week-day-container-height: 4rem;
  --week-day-button-side: 2rem;
  --week-day-button-padding: 0.4375rem;
  --week-day-button-font-size: 1rem;

  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: var(--spacing-5);
  border-bottom: var(--spacing-1) solid var(--widget-stroke-color);
  margin-bottom: -var(--spacing-3);
}

.weekInput > button {
  height: var(--navigate-week-button-height);
  width: var(--navigate-week-button-width);
  padding: unset;
}

.leftChevron {
  margin-right: var(--spacing-3);
}

.rightChevron {
  margin-left: var(--spacing-3);
}

.weekDay {
  width: var(--week-day-container-width);
  height: var(--week-day-container-height);
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
}

.weekDay > button {
  height: var(--week-day-button-side);
  width: var(--week-day-button-side);
  font-size: var(--week-day-button-font-size);
  padding: var(--week-day-button-padding);
  color: var(--default-font-color);
}

.weekDay.selected > button {
  background-color: var(--livi-primary-color) !important;
  color: white !important;
}

.weekDay.disabled > button {
  text-decoration: line-through;
}

.weekDay.today > button {
  border: var(--spacing-1) solid var(--livi-primary-color);
}

.weekDayLabel {
  line-height: 1.5rem;
  text-align: center;
  overflow: hidden;
  width: 100%;
  color: var(--default-font-color);
}

.chevron path {
  fill: var(--default-font-color);
}
