.monthYearLabel {
  --label-height: 2.5rem;

  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: var(--border-radius);
  height: var(--label-height);
  background-color: var(--livi-secondary-color);
  color: var(--default-font-color);
}
