.appWrapper {
  filter: drop-shadow(0 0 var(--spacing-1) grey);
}

.app {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.webAppExpanded {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 330px;
}

.webAppCollapsed {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 42px;
}
