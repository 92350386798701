.radioButtonInput {
  display: block;
  position: relative;
  padding-left: 2.5rem;
  padding-top: 0.4rem;
  cursor: pointer;
  user-select: none;
}

/* Create a custom radioButton */
.checkmark {
  --side-length: 1.5rem;
  --border-radius: 0.375rem;

  position: absolute;
  top: 0;
  left: 0;
  height: var(--side-length);
  width: var(--side-length);
  background-color: var(--input-field-background);
  border: 2px solid var(--widget-stroke-color);
  border-radius: var(--border-radius);
}

/* Create the checkmark/indicator (hidden when not selected) */
.checkmark::after {
  content: '';
  position: absolute;
  display: none;
  border: 2px solid #fff;
  left: 34.38%;
  right: 33.36%;
  bottom: 36.67%;
  top: 0;
  border-top: none;
  border-left: none;
  border-radius: var(--spacing-1);
  transform: translate(12.5%, 12.5%) rotate(45deg);
}

/* Hide the browser's default radioButton */
.radioButtonInput > input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* On mouse-over, change opacity */
.radioButtonInput:hover > input ~ .checkmark {
  opacity: 0.7;
}

/* When the radioButton is checked, add a colored background */
.radioButtonInput > input:checked ~ .checkmark {
  border-color: var(--livi-primary-color);
  background-color: var(--livi-primary-color);
}

@media (prefers-color-scheme: dark) {
  .radioButtonInput > input:checked ~ .checkmark {
    background-color: var(--input-field-background);
    border-color: var(--widget-stroke-color);
  }
}

/* Show the checkmark when checked */
.radioButtonInput > input:checked ~ .checkmark::after {
  display: block;
  opacity: 1;
}

.radioButtonWrapper {
  width: fit-content;
  padding: var(--spacing-5) 0 var(--spacing-5) 0;
}

div > .radioButtonWrapper:nth-child(1) {
  padding: var(--spacing-5) 0 var(--spacing-4) 0;
}

div > .radioButtonWrapper:nth-last-child(1) {
  padding: var(--spacing-4) 0 var(--spacing-5) 0;
}

div > .radioButtonWrapper:not(:first-child):not(:last-child) {
  padding: var(--spacing-4) 0 var(--spacing-4) 0;
}

/* create a normal, circular radio button */
.customRadio {
  position: absolute;
  top: 0.27em;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: var(--input-field-background);
  border-radius: 50%;
  border: 1.75px solid var(--widget-text-soft-color);
}

/* When the radio button is checked */
.radioButtonWrapper input:checked ~ .customRadio {
  background-color: var(--input-field-background);
  border: 2px solid var(--livi-primary-color);
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.customRadio::after {
  content: '';
  position: absolute;
  display: none;
}

/* Style the indicator (dot/circle) */
.radioButtonWrapper .customRadio::after {
  top: 4px;
  left: 4px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: var(--livi-primary-color);
}

/* Show the indicator (dot/circle) when checked */
.radioButtonWrapper input:checked ~ .customRadio::after {
  display: block;
}
