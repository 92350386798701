.unconfirmedBookingCardCircleStatus {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-flex;
  background-color: #ebb500;
  margin-right: 4px;
}

.confirmedBookingCardCircleStatus {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-flex;
  background-color: #8d9295;
  margin-right: 4px;
}

.completedBookingCardCircleStatus {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-flex;
  background-color: #21a69f;
  margin-right: 4px;
}

.cancelledBookingCardCircleStatus {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-flex;
  background-color: #db364f;
  margin-right: 4px;
}

.systemCancelledBookingCardCircleStatus {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-flex;
  background-color: #db364f;
  margin-right: 4px;
}

.bookingStatusCard {
  padding: 5px 5px 2px;
  font-size: 10px;
}
