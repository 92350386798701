:root {
  --header-height: 2.625rem;
  --header-width: 2.5rem;
  --menu-item-divider-width: 0.6875rem;
  --border-radius: 0.5rem;
  --spacing-1: 0.0625rem;
  --spacing-2: 0.125rem;
  --spacing-3: 0.25rem;
  --spacing-4: 0.5rem;
  --spacing-5: 1rem;
  --paragraph-font-size: 0.75rem;
  --h1-font-size: 1.25rem;
  --h2-font-size: 0.9375rem;
  --strong-font-size: 0.875rem;
  --small-font-size: 0.625rem;
  --widget-padding: 1rem;
  --widget-width: 16rem;
  --max-menu-items: 5;
}

@font-face {
  font-family: 'YouSans';
  src: url('../fonts/YouSans-Regular.otf') format('opentype');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'YouSans';
  src: url('../fonts/YouSans-Bold.otf') format('opentype');
  font-style: normal;
  font-weight: bold;
}

@font-face {
  font-family: 'YouSans';
  src: url('../fonts/YouSans-Black.otf') format('opentype');
  font-style: normal;
  font-weight: bolder;
}

@font-face {
  font-family: 'YouSans';
  src: url('../fonts/YouSans-Light.otf') format('opentype');
  font-style: normal;
  font-weight: lighter;
}

@font-face {
  font-family: 'YouSans';
  src: url('../fonts/YouSans-RegularItalic.otf') format('opentype');
  font-style: italic;
  font-weight: normal;
}

@font-face {
  font-family: 'YouSans';
  src: url('../fonts/YouSans-Medium.otf') format('opentype');
  font-style: italic;
  font-weight: 600;
}

label {
  font-size: var(--paragraph-font-size);
  font-weight: 300;
  line-height: 1rem;
  letter-spacing: 0.25px;
}

p {
  font-size: var(--paragraph-font-size);
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: 0.25px;
  margin: 0;
}

p.soft {
  color: var(--widget-text-soft-color);
  line-height: 1.125rem;
}

.center {
  text-align: center;
}

small {
  font-size: var(--small-font-size);
  letter-spacing: 0.25px;
}

strong {
  font-size: var(--strong-font-size);
  line-height: 1.125rem;
  font-weight: 500;
  letter-spacing: 0;
  color: black;
}

body {
  font-family: 'YouSans', sans-serif;
  color: var(--default-font-color);
  letter-spacing: var(--spacing-1);
  font-weight: normal;
  cursor: default;
  overflow-y: hidden;
  overflow-x: hidden;
}

hr {
  border: none;
  height: var(--spacing-1);
}

h1 {
  font-family: 'YouSans', sans-serif;
  font-size: var(--h1-font-size);
  line-height: 1.625rem;
  letter-spacing: 0.25px;
  color: var(--highlight-font-color);
  font-weight: normal;
  margin: 0;
}

h2 {
  margin: 0;
  font-family: 'YouSans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: var(--h2-font-size);
  line-height: 1.1875rem;
  letter-spacing: 0;
  text-align: center;
  color: var(--default-font-color);
}

h3 {
  font-size: var(--h2-font-size);
  line-height: 1.125rem;
  font-weight: 400;
  letter-spacing: 0.25px;
  margin: 0;
}

/* Remove yellow outline for buttons */
button:focus,
button:active:focus,
button.active:focus,
button.active.focus {
  outline: none !important;
}

textarea {
  resize: none;
  height: 2.5em;
  letter-spacing: 0.25px;
}
